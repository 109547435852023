module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cardo","short_name":"Cardo","start_url":"/","background_color":"#fff","theme_color":"#000","display":"minimal-ui","icon":"src/images/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-tracking/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-6922141-4","autoStart":false,"anonymize":true,"controlCookieName":"gdpr-analytics-enabled"},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"cardo.app","customDomain":"plausible.cloud.johnhannagan.com/js/plausible.js?original="},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
